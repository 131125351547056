<template>
  <div class="page form">
    <h2 v-if="!id">新建课程</h2>
    <h2 v-else>
      {{ `编辑课程：${form.title}` }}
      <copy-link :url="'/pages/course/profile?id=' + form.id"/>
      <router-link class="el-button el-button--text" type="text" :to="`/course-list?highlight_id=${id}`">返回列表
      </router-link>
    </h2>

    <section>
      <h3>课程信息</h3>
      <el-form class="form" ref="form" :rules="rules" :model="form" size="small" label-width="140px">
        <el-form-item label="课程名称" prop="title" required>
          <el-input v-model="form.title" autofocus/>
        </el-form-item>
        <el-form-item label="所属类别" prop="klass" required>
          <el-select v-model="form.klass" placeholder="请选择类别">
            <el-option-group v-for="(group, key) in $dbus.config.course_classes" :key="key" :label="key">
              <el-option v-for="item in group" :key="item" :label="item" :value="item"/>
            </el-option-group>
          </el-select>
        </el-form-item>

        <el-form-item label="状态">
          <el-select v-model="form.status" placeholder="选择状态">
            <el-option v-for="t in ['正常', '下架', '隐藏']" :key="t" :label="t" :value="t"/>
          </el-select>
          <div class="tip">设为隐藏状态后，可通过链接进入观看。</div>
        </el-form-item>

        <el-form-item label="课程设置">
          <el-checkbox label="推荐流" v-model="form.is_recommend"/>
          <el-checkbox label="置顶" v-model="form.is_top"/>
          <el-checkbox label="可评论" v-model="form.usable_comment"/>
        </el-form-item>
        <el-form-item label="图文介绍" prop="content">
          <text-editor v-model="form.content"/>
        </el-form-item>
        <el-form-item label="课程封面（课程页）">
          <input-image-single v-model="form.cover"/>
          <div class="tip">推荐尺寸 宽700 x 高350 的 jpg 或 png 图片，图片大小推荐 300KB 以内</div>
        </el-form-item>
        <!--        <el-form-item label="课程封面（推荐页）">-->
        <!--          <input-image-single v-model="form.cover_recommend"/>-->
        <!--          <div class="tip">推荐尺寸 宽700 x 高350 的 jpg 或 png 图片，图片大小推荐 300KB 以内</div>-->
        <!--        </el-form-item>-->
        <el-form-item prop="i_order" label="课程页排序">
          <el-input-number v-model="form.i_order" :min="0"/>
          <div class="tip">数值越大，排序越靠前。</div>
        </el-form-item>
        <el-form-item prop="i_order" label="推荐页排序">
          <el-input-number v-model="form.i_order_recommend"/>
        </el-form-item>
        <el-form-item label="用户限定观看">
          <el-select v-model="form.play_limit_user" placeholder="选择类型" :disabled="!id">
            <el-option label="免费" value="免费"/>
            <el-option label="限会员" value="限会员"/>
            <el-option label="花粉和会员" value="花粉和会员"/>
          </el-select>
          <div class="tip" v-if="!id">创建之后才能修改课程为哪些用户可观看</div>
          <div class="tip" v-else>使课程为哪些用户可观看</div>
        </el-form-item>
        <el-form-item label="观看限制" v-if="form.play_limit_user !== '免费'">
          <el-input-number v-model="form.play_limit_duration"/>
          秒
          <div class="tip">指定范围外的用户，可观看 {{ form.play_limit_duration }} 秒</div>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="onSubmit">提交</el-button>
          <el-button v-if="id" type="danger" @click="onDelete">删除</el-button>
          <router-link class="el-button" type="text" :to="`/course-list?highlight_id=${id}`">返回列表
          </router-link>
        </el-form-item>
      </el-form>
    </section>

    <section v-if="id">
      <h3>章节编辑</h3>
      <el-table :data="chapterList" style="width: 600px">
        <el-table-column width="70" align="right">
          <template slot-scope="scope">
            <span>{{ scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="title" label="章节名称">

        </el-table-column>
        <el-table-column prop="title" width="180" align="right">
          <template slot="header" slot-scope="scope">
            <el-button size="mini" @click="onCreateChapter"> 添加
            </el-button>
          </template>

          <template slot-scope="scope">
            <el-button size="mini" @click="onEditChapter(scope.row)"> 编辑
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </section>
    <!--    <section>-->
    <!--      <h3>评论</h3>-->

    <!--      <el-table :data="comments">-->
    <!--        <el-table-column width="40">-->
    <!--          <template slot-scope="scope">-->
    <!--            {{ scope.$index + 1 }}-->
    <!--          </template>-->
    <!--        </el-table-column>-->

    <!--        <el-table-column label="用户" width="200">-->
    <!--          <template slot-scope="scope">-->
    <!--            <span class="user inline">-->
    <!--              <img :src="scope.row.user.avatar" alt="">-->
    <!--            <span class="name">{{ scope.row.user.nickname }}</span>-->
    <!--            </span>-->
    <!--          </template>-->
    <!--        </el-table-column>-->
    <!--        <el-table-column prop="content" label="评论"></el-table-column>-->
    <!--        <el-table-column width="100">-->
    <!--          <template slot-scope="scope">-->
    <!--            <el-button size="mini" @click="onDeleteComment(scope.row)">删除</el-button>-->
    <!--          </template>-->
    <!--        </el-table-column>-->
    <!--      </el-table>-->
    <!--    </section>-->


  </div>
</template>

<script>
export default {
  data() {
    return {
      id: this.$route.query.id,
      rules: {},
      form: {},
      chapterList: [],
      comments: []
    }
  },
  mounted() {
    if (this.id) {
      this.load()
    }
  },
  methods: {
    load() {
      this.loading = true
      this.$api.request('/course/get_course', {id: this.id})
          .then(data => {
            this.form = data
            this.loading = false
            this.chapterList = data.catalog
            window.scrollTo(0, 0)
          })
    },
    onSubmit() {
      this.loading = true
      this.$refs.form.validate(valid => {
        if (!valid) {
          this.$message.error('请填写必须项')
        }

        let path = this.id ? '/course/update_course' : '/course/create_course'
        this.$api.request(path, this.form).then(data => {
          this.form = data
          this.$router.push({name: 'course-editor', query: {id: data.id}})
          this.loading = false
          this.$message.success('操作成功!')
        })
      })
    },
    onDelete() {
      this.$confirm('您确定要删除吗？这将删除').then(() => {
        this.$api.request('/course/remove_course', {id: this.id}).then(data => {
          this.loading = false
          this.$message.success('删除成功！')
          this.$router.push('/course-list')
        })
      })
    },
    onCreateChapter() {
      this.$router.push({name: 'course-chapter-editor', query: {course_id: this.id}})
    },
    onEditChapter({id}) {
      this.$router.push({name: 'course-chapter-editor', query: {id: id}})
    },
  }
}
</script>
